@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.suspenseLoading{
    &__wrapper{
        background: $bg-color;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__loader{
        width: 10rem;
        height: 10rem;
        padding: 15px;
        margin: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg{
            width: 100%;
            height: 100%;
        }
    }
    
    &__loading-animation__path{
        path{
            stroke: $color-pink;
            stroke-width: 1;
            fill: none;
            transform: scale(.95) translateY(-16px);
            stroke-dasharray: 500;
            stroke-dashoffset: 500;
            animation: strokeAnim 2s linear infinite;
        }
    }
    @keyframes strokeAnim {
        from {
            stroke-dashoffset: 500;
        }
        to {
            stroke-dashoffset: 0;
            
        }
    }

}    

