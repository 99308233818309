@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.backgroundCaptcha{
    display: none;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.75);
    // background-color: red;
    z-index: 1040;
    min-width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;

    .backgroundCaptcha__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1040;
        cursor: pointer;
        &:hover{
            .backgroundCaptcha__close__button{
                opacity: 1;
            }
        }
        // .backgroundCaptcha__close__button{
        //     width: 20px;
        //     height: 20px;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     fill: $text-color;
        //     opacity: .5;
        //     margin: 15px;
        //     transition: .2s;
        // }
    }

    .backgroundCaptchaMainBlock{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // width: 90vw;
        // height: 90vh;
        // background-color:#141722;
        background-color: $bg-color;
        z-index: 1041;
        border-radius: $border-radius;
        outline: 0;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        position: relative;
        min-width: 400px;
        // min-height: 500px;

        .backgroundCaptchaBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // background-color: blue;
            width: 100%;
            padding: 15px;

            .arrowDown{
                opacity: 0;
                width: 30px;
                height: 30px;
                // background-color: $bg-color;
                // border-radius: 5px;
                position: absolute;
                z-index: 1;
                bottom: 5px;
                left: 10px;
                transition: .2s;
                & svg{
                    width: 100%;
                    height: 100%;
                    fill: $text-color;
                    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, 1));
                }
            }
            

            .imageCaptchaBlock{
                overflow: hidden;
                mask-image: -webkit-radial-gradient(white, black);
                position: relative;
                border-radius: $border-radius;
                width: 100%;

                .imageCaptchaBlockSVG{
                    height: 222px;
                    /* background-color: red; */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    & svg{
                        width: 60px;
                        fill: $color-white;
                        opacity: .5;
                    }
                }

                .imageCaptcha{
                    position: absolute;
                    border-radius: $border-radius;
                    max-width: 400px;
                    width: 100%;
                    // height: 240px;
                    z-index: 1;
                }

                .imageCaptchaAttemptsExceeded{
                    position: absolute;
                    transform: translateY(0%);
                    background-color: $color-pink;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    z-index: 1;
                }

                .captchaAttemptsExceeded{
                    animation: captchaAttemptsExceeded 1.5s;
                }
                
                @keyframes captchaAttemptsExceeded {
                    0%{
                        transform: translateY(0%);
                    }
                    15%{
                        transform: translateY(-100%);
                    }
                    85%{
                        transform: translateY(-100%);
                    }
                    100%{
                        transform: translateY(-0%);
                    }
                }

                .imageCaptchaError{
                    position: absolute;
                    transform: translateY(0%);
                    background-color: $color-red-error;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    z-index: 1;
                }

                .captchaError{
                    animation: captchaError 1.5s;
                }
                
                @keyframes captchaError {
                    0%{
                        transform: translateY(0%);
                    }
                    15%{
                        transform: translateY(-100%);
                    }
                    85%{
                        transform: translateY(-100%);
                    }
                    100%{
                        transform: translateY(-0%);
                    }
                }

                .imageCaptchaSuccess{
                    position: absolute;
                    transform: translateY(0%);
                    background-color: $bg-color;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    z-index: 1;
                    // box-shadow: inset 0 0 0 2px $color-pink;
                    border: 2px solid $color-pink;
                    border-radius: 10px;
                    & svg{
                        width: 80px;
                        fill: $color-green;
                    }
                }
                .captchaSuccess{
                    animation: captchaSuccess 1.5s forwards;
                }
                
                @keyframes captchaSuccess {
                    0%{
                        transform: translateY(0%);
                    }
                    15%{
                        transform: translateY(-100.2%);
                    }
                    85%{
                        transform: translateY(-100.2%);
                    }
                    100%{
                        transform: translateY(-0%);
                    }
                }
            }

            .backgroundCaptchaBottomButton{
                // background-color: $color-red;
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                .button{
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    // background-color: blue;
                    &:hover svg{
                        opacity: 1;
                    }
                    & svg{
                        width: 100%;
                        height: 100%;
                        opacity: 0.5;
                        fill: $color-white;
                        transition: 0.2s;
                    }
                }
            }
        }
        .captchaSliderBlock{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 400px;
            height: 60px;
            margin-top: 20px;
            // background-color: red;
            // border: 2px $color-pink solid;
            box-shadow: inset 0 0 0 2px $color-pink;
            border-radius: 10px;
            position: relative;

           
            .captchaSliderCircle{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                border-radius: 10px;
                background-color: $color-pink;
                position: absolute;
                top: -10px;
                left: 0;
                transform: translate(0px, 0px);
                cursor: pointer;
                // transition: width .2s;
                ._trans{
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    justify-content: center;
                    align-items: center;
                    // background-color: red;
                    width: 100%;
                    height: 100%;
                }
                & svg{
                    // width: 25px;
                    height: 30px;
                    fill: $color-white;
                }
                ._trans{
                    position: absolute;
                    // transition: .2s;
                }
            }
        }

    }
    

    ._position{
        position: absolute;
        top: 0;
        left: 0;
    }
    
    @keyframes reloadCaptcha {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    .reloadCaptcha{
        animation: reloadCaptcha 1.5s infinite linear;
    }


    .captchaErrorMainBlock{
        animation: captchaErrorMainBlock .3s;
    }
    
    @keyframes captchaErrorMainBlock {
        0%{
            transform: translateX(0px);
        }
        25%{
            transform: translateX(10px);
        }
        75%{
            transform: translateX(-10px);
        }
        100%{
            transform: translateX(0px);
        }
    }

}

@media (max-width: 449.98px) {
    .backgroundCaptchaMainBlock{
        min-width: 95% !important;
        width: 95%;
    }
}