// $bg-color-body-welcomepage:#31374F;
$bg-color-body-welcomepage:#0A0B10;

$text-color: #eee;
// $text-color-a: rgba(238, 238, 238, 0.5);
$text-color-a: #6b747c;

$color-white: #ffffff;
$color-pink:#8B5AE0; 
// $color-pink:#138BBD; 

$color-pink-a: rgba(139,90,224,0.5);

// $bg-color: #282D43;
$bg-color: #111219;

$color-red: #E50000;
$color-red-error: #962b2b;
$color-red-a: #d13f41;
$color-red-svg:rgb(160, 51, 69);
$color-green-a: rgba(0,255,229,0.2);
$color-green: #77C0C6;
$color-green-svg:#7ac5cb;
$color-green-gradient: linear-gradient(to right, #4B8084, #79BFC5 );
$color-pink-gradient: linear-gradient(to right, #8B5AE1, #AC57CE );
$color-blue: #8da4e4;
$color-blue-s: #667dbd;
$color-warning: #FC8B79;

$border-radius: 10px;
$border-radius-btn-tariff: 50px;

$hoverBlack: rgba(1, 1, 1, 0.2);
$textShadow: rgba(1, 1, 1, 0.6);

$widthSvg : 18px;

:export {
    colorPink: $color-pink;
    colorWhite: $text-color;
    colorWhiteA: $text-color-a;
    colorBackground: $bg-color;
    colorGreenSvg: $color-green-svg;
    colorRedSvg: $color-red-svg;
    colorGreenA: $color-green-a;
    colorPinkSvg: $color-pink;
    bgColor: $bg-color;
}